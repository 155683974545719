#dgViewContainer {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
}

#policy {
    padding: 30px;
}

#letterheadLogo {
    height: 64px;
    width: 64px;
    margin-bottom: 15px;
}

#letterheadReserved {
    position: relative;
    top: -28px;
    left: -5px;
    font-size: 14px;
}

#personalInformation th, #personalInformation td {
    border: 1px solid black;
    padding: 5px;
}

.centerColumn {
    text-align: center;
}